import React from "react";
import "./Home.css";

const Home = (props) => {
     return (
         <>
              <h1>ADAM BROWN</h1>
              <h2>THIS WEBSITE IS UNDER CONSTRUCTION</h2>
         </>
     )
};

export default Home;